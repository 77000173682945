.content {
  img {

    &.right {
      clear: both;
      float: right;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    &.left {
      clear: both;
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 1300px) {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 600px) {
      float: none !important;
      &.right {
        margin-left: 0;
      }
      &.left {
        margin-right: 0;
      }
    }
  }

  figure {
    border-radius: 4px;
    background: $white;
    padding: 20px;
    display: inline-block;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2);
    margin: 0 0 14px 0;

    img {
      border-radius: 4px;
    }
  }
}
