.api-body {
  max-width: 1200px;

  table {
      margin: 12px 0 24px;

      th {
          text-transform: none;
          font-size: 16px;
          font-weight: bold;
      }

      tr {
          border-bottom: 1px solid $lightgray;
      }

      td {
          vertical-align: middle;
      }

      // This is overriding a style here:
      // https://github.com/angular/angular/blob/95993e1/aio/src/styles/2-modules/_table.scss#L58-L62
      tbody > tr > td tr td:first-child {
        @media screen and (max-width: 480px) {
          background-color: inherit;
        }
      }

      hr {
          margin: 16px 0;
      }

      tr:last-child {
          border-bottom: none;
      }

      &.item-table {
          td {
              padding: 32px;
          }
      }

      &.list-table {
          td {
              padding: 16px 24px;
          }
      }

      .short-description {
          margin-left: 0;
      }

      &.parameters-table {
        margin-top: 0;
        font-size: 14px;
        box-shadow: none;

        tr {
          @media screen and (max-width: 480px) {
            display: flex;
            flex-direction: column;
          }

          td:first-child {
            font-weight: 600;
            padding-left: 16px;
            width: 20%;
          }
        }

        td {
          padding: 8px 8px 8px 0;
          border: 0;
          vertical-align: top;
        }
      }

      &.property-table {
        td {
          vertical-align: top;
        }
      }
  }

  .class-overview {
    position: relative;

    code-example {
        clear: left;
    }
  }

  .short-description {
    margin: 6px 0 0 10px;
  }
}

.breadcrumb-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.api-header {
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    margin-top: -4px;
  }
}

.github-links {
  float: right;
  .material-icons {
    border-radius: 4px;
    padding: 4px;
    font-size: 20px;
    &:hover {
      background-color: $mist;
    }
  }
}

.api-body {

  .class-overview {
    position: relative;

    code-example {
        clear: left;
    }
  }

  .method-table, .option-table, .list-table {
    td > code {
      background-color: inherit;
      white-space: pre;
    }

    .with-github-links {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .github-links {
        a {
          color: $mediumgray;
          .material-icons:hover {
            background: none;
            color: $blue;
          }
        }
      }
    }

    h3 {
      margin: 6px 0;
      font-weight: bold;
      clear: left;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      margin-top: 12px;
    }
  }


  .api-heading {
    padding: 5px 0;
    font-size: 14px;
  }

  .parameters-table {
    margin-top: 0;
    font-size: 14px;
    td:nth-child(1) {
      width: 20%;
    }
  }

  details.overloads {
    box-shadow: none;

    .icon-action-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        display: flex;
      }
    }

    .detail-contents {
      padding: 0;
      border: 1px solid $lightgray;
      border-radius: 2px;
      box-shadow: none;

      > *:not(hr) {
        margin: 16px 24px;
      }
    }

    summary {
      height: inherit;
      padding: 0;

      h4 {
        margin: 0;
        clear: left;
      }

      .show-all {
        display: initial;
      }
      .collapse-all {
        display: none;
      }
    }

    &[open] > summary {
      .show-all {
        display: none;
      }
      .collapse-all {
        display: initial;
      }
    }
  }

  details.overload {
    box-shadow: none;
    margin: 0;
  }


  .from-constructor, .read-only-property, .write-only-property {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-style: italic;
    background-color: $lightgray;
    border-radius: 4px;
    padding: 4px 6px;
  }

  .ngmodule-list {
    list-style: none;
    padding: 0;
  }

  .selector-list, .inherited-members-list {
    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 12px;
      }
    }
  }

  .selector-list {
    li, a {
      font-weight: bold;
      i {
        font-weight: normal;
      }
    }
  }
}

.deprecated-api-item {
  text-decoration: line-through;
}
