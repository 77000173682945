.sidenav-content {
  h1, h2, h3, h4, h5, h6 {

    .header-link {
      color: $mediumgray;
      margin: 0 4px;
      text-decoration: none;
      user-select: none;
      visibility: hidden;
      display: inline-block;
      vertical-align: text-top;
    }

    &:hover .header-link {
      visibility: visible;
    }
  }
}
