.CodeMirror {
  height: auto;
  min-height: @simplemde-min-height;
  border: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  font: inherit;
  z-index: 1;
}

.CodeMirror-scroll {
  min-height: @simplemde-min-height;
}

.CodeMirror-fullscreen {
  background: #fff;
  position: fixed !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: @simplemde-zindex;
}

.CodeMirror-sided {
  width: 50% !important;
}

.editor-toolbar {
  position: relative;
  opacity: 0.6;
  user-select: none;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.editor-toolbar:after,
.editor-toolbar:before {
  display: block;
  content: ' ';
  height: 1px;
}

.editor-toolbar:before {
  margin-bottom: 8px;
}

.editor-toolbar:after {
  margin-top: 8px;
}

.editor-toolbar:hover,
.editor-wrapper input.title:focus,
.editor-wrapper input.title:hover {
  opacity: 0.8;
}

.editor-toolbar.fullscreen {
  width: 100%;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: @simplemde-zindex;
}

.editor-toolbar.fullscreen::before {
  width: 20px;
  height: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.editor-toolbar.fullscreen::after {
  width: 20px;
  height: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.editor-toolbar a {
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  color: #2c3e50 !important;
  width: 30px;
  height: 30px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
}

.editor-toolbar a.active,
.editor-toolbar a:hover {
  background: #fcfcfc;
  border-color: #95a5a6;
}

.editor-toolbar a:before {
  line-height: 30px;
}

.editor-toolbar i.separator {
  display: inline-block;
  width: 0;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  color: transparent;
  text-indent: -10px;
  margin: 0 6px;
}

.editor-toolbar a.fa-header-x:after {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 65%;
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
}

.editor-toolbar a.fa-header-1:after {
  content: '1';
}

.editor-toolbar a.fa-header-2:after {
  content: '2';
}

.editor-toolbar a.fa-header-3:after {
  content: '3';
}

.editor-toolbar a.fa-header-bigger:after {
  content: '▲';
}

.editor-toolbar a.fa-header-smaller:after {
  content: '▼';
}

.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  pointer-events: none;
  background: #fff;
  border-color: transparent;
  text-shadow: inherit;
}

@media only screen and (max-width: 700px) {
  .editor-toolbar a.no-mobile {
    display: none;
  }
}

.active .editor-statusbar{display: block}
.editor-statusbar {
  padding: 8px 10px;
  font-size: 12px;
  color: #959694;
  text-align: right;
  display: none;
}

.editor-statusbar span {
  display: inline-block;
  min-width: 4em;
  margin-left: 1em;
}

.editor-statusbar .lines:before {
  content: @simplemde-statusbar-lines;
}

.editor-statusbar .words:before {
  content: @simplemde-statusbar-words;
}

.editor-statusbar .characters:before {
  content: @simplemde-statusbar-characters;
}

.editor-statusbar .counts:before {
  content: @simplemde-statusbar-counts;
}

.editor-preview {
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fafafa;
  z-index: 7;
  overflow: auto;
  display: none;
  box-sizing: border-box;
}

.editor-preview-side {
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 50%;
  top: 50px;
  right: 0;
  background: #fafafa;
  z-index: @simplemde-zindex;
  overflow: auto;
  display: none;
  box-sizing: border-box;
  border: 1px solid #ddd;
}

.editor-preview-active-side {
  display: block;
}

.editor-preview-active {
  display: block;
}

.editor-preview > p,
.editor-preview-side > p {
  margin-top: 0;
}

.editor-preview pre,
.editor-preview-side pre {
  background: #eee;
  margin-bottom: 10px;
}

.editor-preview table td,
.editor-preview table th,
.editor-preview-side table td,
.editor-preview-side table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.CodeMirror .CodeMirror-code .cm-tag {
  color: #63a35c;
}

.CodeMirror .CodeMirror-code .cm-attribute {
  color: #795da3;
}

.CodeMirror .CodeMirror-code .cm-string {
  color: #183691;
}

.CodeMirror .CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror .CodeMirror-code .cm-header-1 {
  font-size: 200%;
  line-height: 200%;
}

.CodeMirror .CodeMirror-code .cm-header-2 {
  font-size: 160%;
  line-height: 160%;
}

.CodeMirror .CodeMirror-code .cm-header-3 {
  font-size: 125%;
  line-height: 125%;
}

.CodeMirror .CodeMirror-code .cm-header-4 {
  font-size: 110%;
  line-height: 110%;
}

.CodeMirror .CodeMirror-code .cm-comment {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.CodeMirror .CodeMirror-code .cm-link {
  color: #7f8c8d;
}

.CodeMirror .CodeMirror-code .cm-url {
  color: #aab2b3;
}

.CodeMirror .CodeMirror-code .cm-strikethrough {
  text-decoration: line-through;
}

.CodeMirror .CodeMirror-placeholder {
  opacity: 0.5;
}
