@font-face {
  font-family: 'simplemde-icon';  /* project id 700857 */
  src: url('@{simplemde-icon-url}.eot');
  src: url('@{simplemde-icon-url}.eot?#iefix') format('embedded-opentype'),
  url('@{simplemde-icon-url}.woff') format('woff'),
  url('@{simplemde-icon-url}.ttf') format('truetype'),
  url('@{simplemde-icon-url}.svg#simplemde-icon') format('svg');
}

.smdi {
  font-family:"simplemde-icon" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smdi-eraser:before { content: "\e89c"; }

.smdi-quote-left:before { content: "\e605"; }

.smdi-undo:before { content: "\e787"; }

.smdi-redo:before { content: "\e788"; }

.smdi-eye:before { content: "\e78f"; }

.smdi-image:before { content: "\e7de"; }

.smdi-table:before { content: "\e7df"; }

.smdi-link:before { content: "\e7e2"; }

.smdi-fullscreen:before { content: "\e7ec"; }

.smdi-list-ul:before { content: "\e7f4"; }

.smdi-list-ol:before { content: "\e7f5"; }

.smdi-bold:before { content: "\e7f7"; }

.smdi-strikethrough:before { content: "\e7f9"; }

.smdi-header:before { content: "\e7f8"; }

.smdi-italic:before { content: "\e7fb"; }

.smdi-code:before { content: "\e7fc"; }

.smdi-columns:before { content: "\e7fd"; }

.smdi-line:before { content: "\e7ff"; }

.smdi-question:before { content: "\e7fe"; }

