.showcase {
  width: 80%;
}

.c-resource-nav {
  width: 20%;
}

.resources-container {
  position: relative;
}

.grid-fixed:after, .grid-fixed:before {
  content: '.';
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed {
    width: auto;
  }
}

@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 20px;
    margin-right: 20px;
    float: none;
    display: block;
    width: auto;
  }
}

@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 480px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 0px;
    margin-right: 0px;
    float: none;
    display: block;
    width: auto;
  }
}

@media handheld and (max-width: 900px), screen and (max-width: 900px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed{
    margin: 0 auto;
    *zoom: 1;
  }
  .grid-fixed:after, .grid-fixed:before, {
    content: '.';
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}

@media handheld and (max-width: 480px), screen and (max-width: 480px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed {
    margin: 0 auto;
    *zoom: 1;
  }
  .grid-fixed:after, .grid-fixed:before {
    content: '.';
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}

aio-resource-list {

  .shadow-1 {
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 1px 4px 0 rgba($black, 0.37);
  }

  .showcase {
    margin-bottom: 8px * 6;
    border-radius: 4px;
  }

  .c-resource {
    h4 {
      margin: 0;
      line-height: 24px;
    }

    p {
      margin: 0;
    }
  }

  .c-resource-nav {
    position: fixed;
    top: 142px;
    right: 32px;
    width: 8px * 20;
    z-index: 1;
    background-color: #fff;
    border-radius: 2px;

    a {
      color: #373E41;
      text-decoration: none;
    }

    .category {
      padding: 10px 0;

      .category-link {
        display: block;
        margin: 2px 0;
        padding: 3px 14px;
        font-size: 18px !important;

        &:hover {
          background: #edf0f2;
          color: #2B85E7;
        }
      }
    }

    .subcategory {
      .subcategory-link {
        display: block;
        margin: 2px 0;
        padding: 4px 14px;

        &:hover {
          background: #edf0f2;
          color: #2B85E7;
        }
      }
    }
  }

  .h-anchor-offset {
    display: block;
    position: relative;
    top: -20px;
    visibility: hidden;
  }

  .l-flex--column {
    display: flex;
    flex-direction: column;
  }

  .c-resource-header {
    margin-bottom: 16px;
  }

  .c-contribute {
    margin-bottom: 24px;
  }

  .c-resource-header h2 {
    margin: 0;
  }

  .subcategory-title {
    padding: 16px 23px;
    margin: 0;
    background-color: $mist;
    color: #373E41;
  }

  .h-capitalize {
    text-transform: capitalize;
  }

  .h-hide {
    display: none;
  }

  .resource-row-link {
    color: #1a2326;
    border: transparent solid 1px;
    margin: 0;
    padding: 16px 23px 16px 23px;
    position: relative;
    text-decoration: none;
    transition: all .3s;
  }

  .resource-row-link:hover {
    color: #1a2326;
    text-decoration: none;
    border-color: #2B85E7;
    border-radius: 4px;
    box-shadow: 0 8px 8px rgba(1, 67, 163, .24), 0 0 8px rgba(1, 67, 163, .12), 0 6px 18px rgba(43, 133, 231, .12);
    transform: translateY(-2px);
  }

  @media(max-width: 900px) {
    .c-resource-nav {
      display: none;
    }
  }
}
