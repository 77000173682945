.filetree {
  background: $offwhite;
  border: 4px solid $lightgray;
  border-radius: 4px;
  margin: 0 0 24px 0;
  padding: 16px 32px;

  .file {
    display: block;
    font-family: $main-font;
    letter-spacing: 0.3px;
    line-height: 32px;
    color: $darkgray;
  }

  .children {
    padding-left: 24px;
    position: relative;
    overflow: hidden;

    .file {
      position: relative;

      &:before {
        content: '';
        left: -18px;
        bottom: 16px;
        width: 16px;
        height: 9999px;
        position: absolute;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: $lightgray;
        border-radius: 0 0 0 3px;
      }
    }
  }
}