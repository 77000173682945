.presskit-container {
  padding: 0 32px 32px 32px;

  h2 {
    color: #37474F;
  }

  .l-space-left-3 {
    margin-left: 3 * 8px;
  }

  .cc-by-anchor {
    text-decoration: underline;
    color: grey !important;
  }

  .presskit-row {
    margin: 48px 0;
    width: 100%;

    .presskit-inner {
      display: flex;
      align-items: center;

      @media(max-width: 599px) {
        flex-direction: column;
      }

      h3 {
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
        color: #455A64;

        @media(max-width: 599px) {
          padding-bottom: 16px;
        }
      }

      .transparent-img-bg {
        margin-top: 10px;
        border-radius: 4px;
        width: 128px;
        height: 128px;
        background-color: #34474F;
      }

      ul {
        padding: 0;
        list-style-type: none;

        @media(max-width: 599px) {
          padding: 0 !important;
          margin: 0 !important;
        }

        li {
          margin: 0 0 8px 0;
        }
      }
    }

    .presskit-image-container {

      @media(max-width: 599px) {
        text-align: center;
      }

      img {
        height: 128px;
        width: auto;
        margin-bottom: 8px * 2;
      }
    }
  }

  .presskit-row:first-child {
    margin-top: 0;

    @media(max-width: 599px) {
      margin-top: 48px;
    }
  }
}
