/* You can add global styles to this file, and also import other style files */
@import './styles/ng-io-theme';
@import './styles/main.scss';

.CodeMirror {
    height: auto;
    min-height: 200px;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
    font: inherit;
    z-index: 1;
    
    padding-top:100px;
  }
  .CodeMirror.active{display: block}
  .CodeMirror-scroll {
    min-height: 200px;
  }
  .doc-viewer{
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
    font: inherit;
    z-index: 1;
    display: block;
    overflow: hidden;
    padding-top:100px;
  }

  .isPreview{display: none!important;}
  .doc-editor{display: inline;}